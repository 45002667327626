import React, { useEffect, useState } from "react";
import axios from "axios";

import success_icon from "../Assets/icons/correct.png";
import first_image from "../Assets/Image-1.png";
import second_image from "../Assets/Image-2.png";
import third_image from "../Assets/Image-3.png";
import awc from "../Assets/all_without_code.png";
import FeatureCard from "../Reusable Components/FeatureCard";

const HomePage = () => {
  const POST_WAITING_LIST_EMAIL = "api/waiting-list-emails";

  const [email, setEmail] = useState();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);
    console.log("Data", email);

    let data = {};
    data.email_id = email;

    console.log({ data });

    try {
      const res = await axios.post(
        `${process.env.REACT_APP_ENDPOINT_URL}${POST_WAITING_LIST_EMAIL}`,
        { data }
      );
      console.log({ res });
    } catch (error) {
      console.log({ error });
      console.error("Cannot save data", error);
    } finally {
      const clear_field = document.getElementById("user_email");
      clear_field.value = "";
      setIsSubmitted(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      setInterval(() => {
        setIsSubmitted(false);
      }, 50000);
    }

    return () => {
      isMounted = false;
    };
  }, [isSubmitted]);

  return (
    <div className="overflow-x-hidden container mx-auto">
      <div className="my-16 ml-8">
        <span className="text-[#f77e7e] font-bold  md:text-lg lg:text-2xl">
          toolable.io
        </span>
      </div>
      <main className="mx-auto flex justify-start md:justify-center text-center mb-10">
        <span className="font-bold text-gray-600 text-2xl md:text-4xl lg:text-5xl lg:px-56">
          <span className="text-[#F66160]">Rediscover your data.</span>Uncover
          endless possibilities.
        </span>
      </main>
      <div className="mx-2 md:mx-36 lg:mx-72 flex lg:justify-center text-center mb-10">
        <span className="font-medium text-slate-500 text-xs lg:text-xl">
          Collect, organize &amp; understand your data. Utilize and collaborate
          to get more insights. Create workflows around it and build apps that
          suit your business requirements.
        </span>
      </div>
      <div className="flex justify-center  mb-10 lg:mb-20">
        <img src={awc} className="h-12 w-40 md:h-16 w-50 lg:h-24 lg:w-64" />
      </div>
      <div className="grid lg:grid-cols-3 gap-8 mx-auto mb-20">
        <div className="flex flex-col items-center">
          <div className="pl-16 md:pl-20">
            <img
              src={first_image}
              className="h-64 w-60 mx-auto"
              alt="Img not found"
            />
          </div>
          <span className="font-semibold text-slate-500 text-sm text-center w-1/2 lg:text-xl">
            Build custom forms and collect data or information
          </span>
        </div>
        <div className="flex flex-col items-center">
          <img
            src={second_image}
            className="h-64 w-60 mx-auto"
            alt="Img not found"
          />
          <span className="font-semibold text-slate-500 text-sm text-center w-1/2 lg:text-xl">
            Manage, collaborate, share your data and create workflows
          </span>
        </div>
        <div className="flex flex-col items-center">
          <img
            src={third_image}
            className="h-64 w-60 mx-auto"
            alt="Img not found"
          />
          <span className="font-semibold text-slate-500 text-sm text-center w-1/2 lg:text-xl">
            Create apps to suit your business requirements
          </span>
        </div>
      </div>

      {isSubmitted ? (
        <div className="bg-slate-50 shadow-lg w-2/5 py-9 rounded mx-auto mb-32 flex items-center justify-center">
          <div className="text-slate-700 ">
            <img
              src={success_icon}
              className="mx-auto py-3 animate-pulse duration-200 transition "
            ></img>
            <p className="text-center text-4xl pb-3 font-semibold">Success</p>
            <p className="text-center ">
              We received your response. We'll be in touch shortly.
            </p>
          </div>
        </div>
      ) : (
        <div className="flex justify-center mb-32 lg:pt-9">
          <form
            onSubmit={(e) => onSubmit(e)}
            className="w-full md:w-2/3 flex flex-col lg:flex-row lg:justify-center gap-3 px-9"
          >
            <input
              id="user_email"
              type="email"
              name="email_id"
              placeholder="Enter your email"
              className="border border-solid p-2 bg-gray-100 outline-none lg:w-2/4"
              autoComplete="off"
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              // onClick={() => onSubmit()}
              className="bg-[#F66160] p-2 lg:w-2/5
             text-white cursor-pointer font-normal rounded-sm"
            >
              {!isLoading ? (
                <p>Join waiting list</p>
              ) : (
                <p className="flex items-center justify-center">
                  <svg
                    className="animate-spin -ml-1 mr-2 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className=""
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="#F66160"
                      strokeWidth="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
                  <span className="text-primary">Processing...</span>
                </p>
              )}
            </button>
          </form>
        </div>
      )}

      {/* <div className="flex justify-center mb-20">
        <span className="font-bold text-[##4B5563] text-lg text-center lg:text-2xl">
          Features
        </span>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 mb-20 mx-auto">
        <FeatureCard feature_title="Create custom UI" />
        <FeatureCard feature_title="Create workflows" />
        <FeatureCard feature_title="Custom access controls" />
        <FeatureCard feature_title="Configure triggers & notifications" />
        <FeatureCard feature_title="Integrate with OTP, SMS, whatsapp or email" />
        <FeatureCard feature_title="Expose APIs" />
        <FeatureCard feature_title="Offline Support" />
        <FeatureCard feature_title="Data & transaction security" />
        <FeatureCard feature_title="Data visualization and drill down reports" />
      </div> */}
    </div>
  );
};

export default HomePage;
